import { recipeProcessor } from './someFunc.js';
import { makingRecipeFunctions } from './makingRecipeFunctions.js';

function init() {
    recipeProcessor();
    makingRecipeFunctions();
}

export {
    init   
}